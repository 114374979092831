import React, { useContext, useEffect } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Button from 'components/button/Button';
import List from '@material-ui/core/List';
import { useLocation, matchPath } from 'react-router-dom';
import localforage from 'localforage';
import DrawerContext from 'contexts/DrawerContext';
import parcoursContext from 'contexts/ParcourContext';
import Img from 'assets/images/fleche_fu.png';
import userContext from 'contexts/UserContext';
import { setAuthorizationBearer, client } from 'requests/client';
import classNames from 'utils/classNames';
import { useUpdateParcour } from 'requests/parcours';
import { useLogout } from 'requests/auth';

import useStyles from './styles';
import { usePosthog } from '../../../hooks/usePosthog';

const PrivateDrawer = () => {
  const { setUser, user } = useContext(userContext);
  const [logoutCall] = useLogout();

  const userLinks = [
    { text: 'TABLEAU DE BORD', path: '/' },
    { text: "CONDITIONS GÉNÉRALES D'UTILISATION", path: `https://diagoriente.beta.gouv.fr/conditions-generales` },
    { text: 'POLITIQUE DE CONFIDENTIALITÉ', path: `https://diagoriente.beta.gouv.fr/confidentialite` },
    { text: 'MENTIONS LÉGALES', path: 'https://diagoriente.beta.gouv.fr/mentions-legales' },
    { text: 'DÉCONNEXION', path: '/' },
  ];

  const adminLinks = [
    { text: 'Thèmes', path: '/admin/themes' },
    { text: 'Activités', path: '/admin/activities' },
    { text: 'Contextes', path: '/admin/contexts' },
    { text: 'Institution', path: '/admin/institution' },
    { text: 'Options', path: '/admin/options' },
    { text: 'Questions', path: '/admin/questions' },
    { text: 'Utilisateurs', path: '/admin/users' },
    { text: 'Scopes', path: '/admin/scopes' },
    { text: 'Paramètre', path: '/admin/parametre' },
    { text: 'DÉCONNEXION', path: '/' },
  ];

  const advisorLinks = [
    { text: 'Parcours', path: '/advisor/parcours' },
    { text: 'DÉCONNEXION', path: '/' },
  ];
  const { setParcours, parcours } = useContext(parcoursContext);
  const location = useLocation();
  const isJobs = Boolean(matchPath(location.pathname, { path: '/jobs', exact: true }));
  const classes = useStyles({ isCampus: false });
  const [updateCompleteCall, updateCompeteState] = useUpdateParcour();
  const { open, setOpen } = useContext(DrawerContext);
  const { enableAnalytics, posthog } = usePosthog();

  const logout = () => {
    if (enableAnalytics) {
      posthog!.capture("S'est déconnecté");
      posthog!.reset();
    }
    logoutCall();
    localforage.removeItem('auth');
    setParcours(null);
    setUser(null);
    localStorage.clear();
    sessionStorage.clear();
    client.clearStore();
    sessionStorage.setItem('hasLoggedOut', 'true');

    setTimeout(() => {
      setAuthorizationBearer('');
    }, 200);
  };

  const onClose = () => {
    setOpen(false);
  };

  /* const links = user?.role === 'user' ? userLinks : adminLinks; */
  let links = [];
  switch (user?.role) {
    case 'advisor': {
      links = advisorLinks;
      break;
    }
    case 'user': {
      links = userLinks;
      break;
    }
    case 'admin': {
      links = adminLinks;
      break;
    }
    default: {
      links = userLinks;
    }
  }

  useEffect(() => {
    if (!parcours?.completed && isJobs) {
      setOpen(true);
    }
  }, [parcours, setOpen, isJobs]);

  const onSubmit = () => {
    setOpen(false);
    if (!parcours?.completed) {
      updateCompleteCall({ variables: { completed: true } });
    }
  };

  useEffect(() => {
    if (updateCompeteState.data) {
      setParcours(updateCompeteState.data.updateParcour);
    }
  }, [updateCompeteState.data, setParcours]);

  return (
    <>
      <Drawer
        variant="temporary"
        anchor="top"
        open={open}
        classes={{
          paper: classes.drawerPaper,
          root: classes.root,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        onClose={onClose}
      >
        <div className={classes.toolbar} />
        <List className={classes.root}>
          {links.map((e) => (
            <li key={e.text} className={classes.linkContainer} onClick={e.text === 'DÉCONNEXION' ? logout : () => {}}>
              <a href={e.path} target={e.text === 'AIDE' ? '_blank' : ''} rel="noreferrer">
                <div
                  className={classNames(
                    isJobs ? classes.linkJob : classes.link,
                    !parcours?.completed && isJobs && e.text === 'TABLEAU DE BORD' && classes.firstUseLink,
                  )}
                >
                  {e.text}
                </div>
              </a>
            </li>
          ))}
        </List>
      </Drawer>
      {!parcours?.completed && isJobs && open && (
        <div
          style={{
            position: 'absolute',
            top: 65,
            left: 200,
            zIndex: 9999,
            display: 'flex',
          }}
        >
          <div>
            <img alt="" src={Img} />
          </div>
          <div>
            <div className={classes.textMsg}>
              Pour compléter ton profil et retrouver à tout moment toutes tes informations, rends toi dans ton tableau
              de bord, accessible via le menu.
            </div>
            <Button className={classes.btn} onClick={onSubmit}>
              <div className={classes.btnLabel}>Compris !</div>
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default PrivateDrawer;
