/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import classNames from 'utils/classNames';

import twitter from 'assets/svg/twitter.svg';
import linkedin from 'assets/svg/linkedin.svg';
import youtube from 'assets/svg/youtube.svg';
import Grid from '@material-ui/core/Grid';

import useStyles from './styles';

const Footer = () => {
  const classes = useStyles();
  return (
    <footer className={classes.footerContainer}>
      <div className={classes.iconContainer}>
        <div className={classNames(classes.circle, classes.circleTwitter)}>
          <a href="https://twitter.com/diagoriente">
            <img src={twitter} alt="twitter" />
          </a>
        </div>
        <div className={classNames(classes.circle, classes.circleLinkedin, classes.marginIcons)}>
          <a href="http://www.linkedin.com/company/diagoriente/">
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>
        <div className={classes.circle}>
          <a href="https://www.youtube.com/channel/UCfh-72vbjMaa-ZFzKIAF1Dw">
            <img src={youtube} alt="youtube" />
          </a>
        </div>
      </div>
      <Grid container spacing={3} className={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <p className={classes.textTop}>
            <a className={classes.link} href="https://diagoriente.beta.gouv.fr/conditions-generales" target="_blank">
              Conditions générales d&apos;utilisation
            </a>
            <br />
            <a className={classes.link} href="https://diagoriente.beta.gouv.fr/mentions-legales" target="_blank">
              Mentions légales
            </a>
            <br />
            <a className={classes.link} href="https://diagoriente.beta.gouv.fr/confidentialite" target="_blank">
              Politique de confidentialité
            </a>
            <br />
            <a className={classes.link} href="https://diagoriente.beta.gouv.fr/contact" target="_blank">
              Nous contacter
            </a>
          </p>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
