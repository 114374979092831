import gql from 'graphql-tag';
import { QueryHookOptions } from '@apollo/react-hooks';
import { useLocalLazyQuery } from '../hooks/apollo';

export const analyticsProfileQuery = gql`
  query {
    myAnalyticsProfile {
      nombreConnexions
      nombreExperiencesAjoutees
      nombreMetiersFavoris
      nombreInteretsAjoutes
      nombreRecherchesFormations
      nombreRecherchesImmersions
      nombreFichesMetiersConsultees
    }
  }
`;

export interface AnalyticsProfileResponse {
  myAnalyticsProfile: {
    nombreConnexions: number;
    nombreRecherchesFormations: number;
    nombreRecherchesImmersions: number;
    nombreInteretsAjoutes: number;
    nombreExperiencesAjoutees: number;
    nombreMetiersFavoris: number;
    nombreFichesMetiersConsultees: number;
  };
}

export const useMyAnalyticsProfile = (options: QueryHookOptions<AnalyticsProfileResponse> = {}) =>
  useLocalLazyQuery<AnalyticsProfileResponse>(analyticsProfileQuery, options);
